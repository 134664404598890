

export class FuelTypeAddInput implements IFuelTypeAddInput {
    /** The fuel type name with 255 character limit */
    fuelTypeName!: string;

    constructor(data?: IFuelTypeAddInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.fuelTypeName = _data["fuelTypeName"];
        }
    }

    static fromJS(data: any): FuelTypeAddInput {
        data = typeof data === 'object' ? data : {};
        let result = new FuelTypeAddInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["fuelTypeName"] = this.fuelTypeName;
        return data; 
    }
}

export interface IFuelTypeAddInput {
    /** The fuel type name with 255 character limit */
    fuelTypeName: string;
}

