

export class TimeZoneAddInput implements ITimeZoneAddInput {
    /** The time zone with 255 character limit */
    timeZone!: string;
    /** The time difference between time zone time zone master */
    timeDifference!: number;

    constructor(data?: ITimeZoneAddInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.timeZone = _data["timeZone"];
            this.timeDifference = _data["timeDifference"];
        }
    }

    static fromJS(data: any): TimeZoneAddInput {
        data = typeof data === 'object' ? data : {};
        let result = new TimeZoneAddInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["timeZone"] = this.timeZone;
        data["timeDifference"] = this.timeDifference;
        return data; 
    }
}

export interface ITimeZoneAddInput {
    /** The time zone with 255 character limit */
    timeZone: string;
    /** The time difference between time zone time zone master */
    timeDifference: number;
}

