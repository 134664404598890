import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideFirebaseApp } from '@angular/fire/app';
import { initializeApp } from 'firebase/app';
import { environment } from '../environments/environment';
import { getFirestore } from 'firebase/firestore';
import { provideFirestore } from '@angular/fire/firestore';
import { API_BASE_URL } from './service-proxies/service-proxies';
import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { LoaderInterceptor, TokenInterceptor } from './interceptor';
import {
  CommonServiceProxy,

  PoiDetailsServiceProxy,
  GeoFenceServiceProxy,
  UserLicenseDetailsServiceProxy, CompanyLicenseDetailsServiceProxy, VehicleLicenseDetailsServiceProxy,

  VehicleGroupServiceProxy, EmployeeGroupServiceProxy, RoutePathServiceProxy, RouteGroupServiceProxy,

  CompanyDetailServiceProxy,
  BranchDetailServiceProxy,
  VehicleDetailsServiceProxy,
  EmployeeDetailsServiceProxy,
  CompanyRuleDetailsServiceProxy,
  SmsAllocationServiceProxy,
  CustomizeToolTipServiceProxy,

  ApplicationMenuServiceProxy,
  ApplicationModuleServiceProxy,
  UserTypeServiceProxy,
  LanguageServiceProxy,
  ReminderServiceProxy,
  CountryServiceProxy, StateServiceProxy, CityServiceProxy,
  AlertServiceProxy, TyreBrandServiceProxy, TyreModelServiceProxy,
  ToolTipHeaderServiceProxy, ToolTipOptionServiceProxy,
  StorageDaysServiceProxy, MapServiceProxy, DocumentTypeServiceProxy,
  FuelTypeServiceProxy, PoiTypeServiceProxy, FuelStationServiceProxy,
  ExpenseTypeServiceProxy, MaintenanceTypeServiceProxy, ExpenseServiceProxy,
  VehicleTypeServiceProxy, VehicleCompanyServiceProxy, VehicleModelServiceProxy,
  GPSDeviceServiceProxy,
  TariffPlanServiceProxy, SimCardServiceProxy,
  DeviceBrandServiceProxy, DeviceModelServiceProxy, DeviceModelPortAllocationServiceProxy, DesignationServiceProxy,
  LicenseToDriveServiceProxy, TimeZoneServiceProxy,
  TyreServiceProxy,
  AlertGroupServiceProxy,
  UserGroupServiceProxy,
  UserServiceProxy,
  DeviceServiceProxy,

  TemperatureCalibrationServiceProxy,


  TourAllocationServiceProxy


} from './service-proxies';
import { CommonFunctions } from './service-proxies/common-functions';
import { IMAGE_BASE_URL } from './services';
import { FuelCalibrationServiceProxy } from './service-proxies/services/fuel-calibration.service';
import { TourServiceProxy } from './service-proxies/services/tour.service';


export function GetRemoteServiceBaseUrl(): string {
  return environment.baseURL;
}

export function GetRemoteImageBaseUrl(): string {
  return environment.imageURL;
}

export const appConfig: ApplicationConfig = {
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    provideRouter(routes),
    provideClientHydration(),
    provideAnimations(),
    provideHttpClient(withFetch(), withInterceptorsFromDi()),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => getFirestore()),
    { provide: API_BASE_URL, useFactory: GetRemoteServiceBaseUrl },
    { provide: IMAGE_BASE_URL, useFactory: GetRemoteImageBaseUrl },
    //{ provide: HTTP_INTERCEPTORS, useClass: tokenInterceptor, multi: true },

    CommonFunctions,
    CommonServiceProxy,

    // Geofence Module
    GeoFenceServiceProxy,

    //Temperature Module
    TemperatureCalibrationServiceProxy,

    //Poi Module
    PoiDetailsServiceProxy,

    //Tyre Module
    TyreServiceProxy,

    // Tour Module
    TourServiceProxy,
    TourAllocationServiceProxy,

    //Alert Module
    AlertGroupServiceProxy,

    //Licensing Module
    UserLicenseDetailsServiceProxy,
    CompanyLicenseDetailsServiceProxy,
    VehicleLicenseDetailsServiceProxy,

    //Maintenance Module
    ExpenseServiceProxy,
    ReminderServiceProxy,

    //Advance Module
    VehicleGroupServiceProxy,
    EmployeeGroupServiceProxy,
    RoutePathServiceProxy,
    RouteGroupServiceProxy,

    //App ConfigModule
    CompanyDetailServiceProxy,
    BranchDetailServiceProxy,
    VehicleDetailsServiceProxy,
    EmployeeDetailsServiceProxy,
    GPSDeviceServiceProxy,
    CompanyRuleDetailsServiceProxy,
    SmsAllocationServiceProxy,
    CustomizeToolTipServiceProxy,

    //User-rights Module
    UserGroupServiceProxy,
    UserServiceProxy,

    //Master Module
    ApplicationMenuServiceProxy,
    ApplicationModuleServiceProxy,
    UserTypeServiceProxy,
    LanguageServiceProxy,
    CountryServiceProxy,
    StateServiceProxy,
    CityServiceProxy,
    AlertServiceProxy,
    TyreBrandServiceProxy,
    TyreModelServiceProxy,
    ToolTipHeaderServiceProxy,
    ToolTipOptionServiceProxy,
    StorageDaysServiceProxy,
    MapServiceProxy,
    DocumentTypeServiceProxy,
    FuelTypeServiceProxy,
    PoiTypeServiceProxy,
    FuelStationServiceProxy,
    ExpenseTypeServiceProxy,
    MaintenanceTypeServiceProxy,
    VehicleTypeServiceProxy,
    VehicleCompanyServiceProxy,
    VehicleModelServiceProxy,
    TariffPlanServiceProxy,
    SimCardServiceProxy,
    DeviceBrandServiceProxy,
    DeviceModelServiceProxy,
    DeviceModelPortAllocationServiceProxy,
    DeviceServiceProxy,
    DesignationServiceProxy,
    LicenseToDriveServiceProxy,
    TimeZoneServiceProxy,
    VehicleDetailsServiceProxy,
    FuelCalibrationServiceProxy
  ]
};