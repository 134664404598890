import { HttpClient, HttpHeaders, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable, of as _observableOf, throwError as _observableThrow } from 'rxjs';
import { catchError as _observableCatch, mergeMap as _observableMergeMap } from 'rxjs/operators';
import { CommonFunctions } from '../common-functions';
import { AddMainMenuInput } from '../models/add-main-menu-input.model';
import { AddMainMenuOutput } from '../models/add-main-menu-output.model';
import { AddSubMenuInput } from '../models/add-sub-menu-input.model';
import { AddSubMenuOutput } from '../models/add-sub-menu-output.model';
import { SelectActiveMainMenuOutput } from '../models/select-active-main-menu-output.model';
import { SelectActiveSubMenuOutput } from '../models/select-active-sub-menu-output.model';
import { SelectAllMainMenuOutput } from '../models/select-all-main-menu-output.model';
import { SelectAllSubMenuOutput } from '../models/select-all-sub-menu-output.model';
import { SelectApplicationMenuForUserGroupOutput } from '../models/select-application-menu-for-user-group-output.model';
import { SelectApplicationSubMenuDemoTableInput } from '../models/select-application-sub-menu-demo-table-input.model';
import { SelectApplicationSubMenuDemoTableOutput } from '../models/select-application-sub-menu-demo-table-output.model';
import { SelectByMainMenuIdOutput } from '../models/select-by-main-menu-id-output.model';
import { SelectByMenuSubIdOutput } from '../models/select-by-menu-sub-id-output.model';
import { SelectMainMenuNextIndexNumberOutput } from '../models/select-main-menu-next-index-number-output.model';
import { SelectSubMenuNextIndexNumberOutput } from '../models/select-sub-menu-next-index-number-output.model';
import { StatusMainMenuInput } from '../models/status-main-menu-input.model';
import { StatusMainMenuOutput } from '../models/status-main-menu-output.model';
import { StatusSubMenuInput } from '../models/status-sub-menu-input.model';
import { StatusSubMenuOutput } from '../models/status-sub-menu-output.model';
import { UpdateMainMenuInput } from '../models/update-main-menu-input.model';
import { UpdateMainMenuOutput } from '../models/update-main-menu-output.model';
import { UpdateSubMenuInput } from '../models/update-sub-menu-input.model';
import { UpdateSubMenuOutput } from '../models/update-sub-menu-output.model';
import { API_BASE_URL } from '../service-proxies';

@Injectable()
export class ApplicationMenuServiceProxy {
    private http: HttpClient;
    private baseUrl: string;
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

    constructor(private _commonFunctions: CommonFunctions, @Inject(HttpClient) http: HttpClient, @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
        this.http = http;
        this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : "http://localhost:3001/api/v1";
    }

    /**
     * @return Application menu next index selected successfully.
     */
    selectMainMenuNextIndexNumber(): Observable<SelectMainMenuNextIndexNumberOutput> {
        let url_ = this.baseUrl + "/ApplicationMenu/SelectMainMenuNextIndexNumber";
        url_ = url_.replace(/[?&]$/, "");

        let options_ : any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Accept": "application/json"
            })
        };

        return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processSelectMainMenuNextIndexNumber(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processSelectMainMenuNextIndexNumber(<any>response_);
                } catch (e) {
                    return <Observable<SelectMainMenuNextIndexNumberOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<SelectMainMenuNextIndexNumberOutput>><any>_observableThrow(response_);
        }));
    }

    protected processSelectMainMenuNextIndexNumber(response: HttpResponseBase): Observable<SelectMainMenuNextIndexNumberOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = SelectMainMenuNextIndexNumberOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<SelectMainMenuNextIndexNumberOutput>(<any>null);
    }

    /**
     * @return Application main menu added successfully.
     */
    addMainMenu(body: AddMainMenuInput): Observable<AddMainMenuOutput> {
        let url_ = this.baseUrl + "/ApplicationMenu/AddMainMenu";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_ : any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("post", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processAddMainMenu(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processAddMainMenu(<any>response_);
                } catch (e) {
                    return <Observable<AddMainMenuOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<AddMainMenuOutput>><any>_observableThrow(response_);
        }));
    }

    protected processAddMainMenu(response: HttpResponseBase): Observable<AddMainMenuOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = AddMainMenuOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<AddMainMenuOutput>(<any>null);
    }

    /**
     * @return Application main menu updated successfully.
     */
    updateMainMenu(body: UpdateMainMenuInput): Observable<UpdateMainMenuOutput> {
        let url_ = this.baseUrl + "/ApplicationMenu/UpdateMainMenu";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_ : any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("put", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processUpdateMainMenu(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processUpdateMainMenu(<any>response_);
                } catch (e) {
                    return <Observable<UpdateMainMenuOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<UpdateMainMenuOutput>><any>_observableThrow(response_);
        }));
    }

    protected processUpdateMainMenu(response: HttpResponseBase): Observable<UpdateMainMenuOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = UpdateMainMenuOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<UpdateMainMenuOutput>(<any>null);
    }

    /**
     * @return Application main menu status updated successfully.
     */
    statusMainMenu(body: StatusMainMenuInput): Observable<StatusMainMenuOutput> {
        let url_ = this.baseUrl + "/ApplicationMenu/StatusMainMenu";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_ : any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("put", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processStatusMainMenu(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processStatusMainMenu(<any>response_);
                } catch (e) {
                    return <Observable<StatusMainMenuOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<StatusMainMenuOutput>><any>_observableThrow(response_);
        }));
    }

    protected processStatusMainMenu(response: HttpResponseBase): Observable<StatusMainMenuOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = StatusMainMenuOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<StatusMainMenuOutput>(<any>null);
    }

    /**
     * @return All application main menu selected successfully.
     */
    selectAllMainMenu(): Observable<SelectAllMainMenuOutput> {
        let url_ = this.baseUrl + "/ApplicationMenu/SelectAllMainMenu";
        url_ = url_.replace(/[?&]$/, "");

        let options_ : any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Accept": "application/json"
            })
        };

        return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processSelectAllMainMenu(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processSelectAllMainMenu(<any>response_);
                } catch (e) {
                    return <Observable<SelectAllMainMenuOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<SelectAllMainMenuOutput>><any>_observableThrow(response_);
        }));
    }

    protected processSelectAllMainMenu(response: HttpResponseBase): Observable<SelectAllMainMenuOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = SelectAllMainMenuOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<SelectAllMainMenuOutput>(<any>null);
    }

    /**
     * @return Active application main menu selected successfully.
     */
    selectActiveMainMenu(): Observable<SelectActiveMainMenuOutput> {
        let url_ = this.baseUrl + "/ApplicationMenu/SelectActiveMainMenu";
        url_ = url_.replace(/[?&]$/, "");

        let options_ : any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Accept": "application/json"
            })
        };

        return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processSelectActiveMainMenu(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processSelectActiveMainMenu(<any>response_);
                } catch (e) {
                    return <Observable<SelectActiveMainMenuOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<SelectActiveMainMenuOutput>><any>_observableThrow(response_);
        }));
    }

    protected processSelectActiveMainMenu(response: HttpResponseBase): Observable<SelectActiveMainMenuOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = SelectActiveMainMenuOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<SelectActiveMainMenuOutput>(<any>null);
    }

    /**
     * @return Application main menu selected successfully.
     */
    selectMainMenuById(applicationMenuId: number): Observable<SelectByMainMenuIdOutput> {
        let url_ = this.baseUrl + "/ApplicationMenu/SelectMainMenuById/{applicationMenuId}";
        if (applicationMenuId === undefined || applicationMenuId === null)
            throw new Error("The parameter 'applicationMenuId' must be defined.");
        url_ = url_.replace("{applicationMenuId}", encodeURIComponent("" + applicationMenuId));
        url_ = url_.replace(/[?&]$/, "");

        let options_ : any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Accept": "application/json"
            })
        };

        return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processSelectMainMenuById(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processSelectMainMenuById(<any>response_);
                } catch (e) {
                    return <Observable<SelectByMainMenuIdOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<SelectByMainMenuIdOutput>><any>_observableThrow(response_);
        }));
    }

    protected processSelectMainMenuById(response: HttpResponseBase): Observable<SelectByMainMenuIdOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = SelectByMainMenuIdOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<SelectByMainMenuIdOutput>(<any>null);
    }

    /**
     * @return Application sub menu next index selected successfully.
     */
    selectSubMenuNextIndexNumber(applicationMenuId: number): Observable<SelectSubMenuNextIndexNumberOutput> {
        let url_ = this.baseUrl + "/ApplicationMenu/SelectSubMenuNextIndexNumber/{applicationMenuId}";
        if (applicationMenuId === undefined || applicationMenuId === null)
            throw new Error("The parameter 'applicationMenuId' must be defined.");
        url_ = url_.replace("{applicationMenuId}", encodeURIComponent("" + applicationMenuId));
        url_ = url_.replace(/[?&]$/, "");

        let options_ : any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Accept": "application/json"
            })
        };

        return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processSelectSubMenuNextIndexNumber(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processSelectSubMenuNextIndexNumber(<any>response_);
                } catch (e) {
                    return <Observable<SelectSubMenuNextIndexNumberOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<SelectSubMenuNextIndexNumberOutput>><any>_observableThrow(response_);
        }));
    }

    protected processSelectSubMenuNextIndexNumber(response: HttpResponseBase): Observable<SelectSubMenuNextIndexNumberOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = SelectSubMenuNextIndexNumberOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<SelectSubMenuNextIndexNumberOutput>(<any>null);
    }

    /**
     * @return Application sub menu added successfully.
     */
    addSubMenu(body: AddSubMenuInput): Observable<AddSubMenuOutput> {
        let url_ = this.baseUrl + "/ApplicationMenu/AddSubMenu";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_ : any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("post", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processAddSubMenu(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processAddSubMenu(<any>response_);
                } catch (e) {
                    return <Observable<AddSubMenuOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<AddSubMenuOutput>><any>_observableThrow(response_);
        }));
    }

    protected processAddSubMenu(response: HttpResponseBase): Observable<AddSubMenuOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = AddSubMenuOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<AddSubMenuOutput>(<any>null);
    }

    /**
     * @return Application sub menu updated successfully.
     */
    updateSubMenu(body: UpdateSubMenuInput): Observable<UpdateSubMenuOutput> {
        let url_ = this.baseUrl + "/ApplicationMenu/UpdateSubMenu";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_ : any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("put", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processUpdateSubMenu(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processUpdateSubMenu(<any>response_);
                } catch (e) {
                    return <Observable<UpdateSubMenuOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<UpdateSubMenuOutput>><any>_observableThrow(response_);
        }));
    }

    protected processUpdateSubMenu(response: HttpResponseBase): Observable<UpdateSubMenuOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = UpdateSubMenuOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<UpdateSubMenuOutput>(<any>null);
    }

    /**
     * @return Application sub menu status updated successfully.
     */
    statusSubMenu(body: StatusSubMenuInput): Observable<StatusSubMenuOutput> {
        let url_ = this.baseUrl + "/ApplicationMenu/StatusSubMenu";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_ : any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("put", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processStatusSubMenu(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processStatusSubMenu(<any>response_);
                } catch (e) {
                    return <Observable<StatusSubMenuOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<StatusSubMenuOutput>><any>_observableThrow(response_);
        }));
    }

    protected processStatusSubMenu(response: HttpResponseBase): Observable<StatusSubMenuOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = StatusSubMenuOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<StatusSubMenuOutput>(<any>null);
    }

    /**
     * @return All application sub menu selected successfully.
     */
    selectAllSubMenu(applicationMenuId: number): Observable<SelectAllSubMenuOutput> {
        let url_ = this.baseUrl + "/ApplicationMenu/SelectAllSubMenu/{applicationMenuId}";
        if (applicationMenuId === undefined || applicationMenuId === null)
            throw new Error("The parameter 'applicationMenuId' must be defined.");
        url_ = url_.replace("{applicationMenuId}", encodeURIComponent("" + applicationMenuId));
        url_ = url_.replace(/[?&]$/, "");

        let options_ : any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Accept": "application/json"
            })
        };

        return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processSelectAllSubMenu(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processSelectAllSubMenu(<any>response_);
                } catch (e) {
                    return <Observable<SelectAllSubMenuOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<SelectAllSubMenuOutput>><any>_observableThrow(response_);
        }));
    }

    protected processSelectAllSubMenu(response: HttpResponseBase): Observable<SelectAllSubMenuOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = SelectAllSubMenuOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<SelectAllSubMenuOutput>(<any>null);
    }

    /**
     * @return Active application sub menu selected successfully.
     */
    selectActiveSubMenu(applicationMenuId: number): Observable<SelectActiveSubMenuOutput> {
        let url_ = this.baseUrl + "/ApplicationMenu/SelectActiveSubMenu/{applicationMenuId}";
        if (applicationMenuId === undefined || applicationMenuId === null)
            throw new Error("The parameter 'applicationMenuId' must be defined.");
        url_ = url_.replace("{applicationMenuId}", encodeURIComponent("" + applicationMenuId));
        url_ = url_.replace(/[?&]$/, "");

        let options_ : any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Accept": "application/json"
            })
        };

        return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processSelectActiveSubMenu(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processSelectActiveSubMenu(<any>response_);
                } catch (e) {
                    return <Observable<SelectActiveSubMenuOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<SelectActiveSubMenuOutput>><any>_observableThrow(response_);
        }));
    }

    protected processSelectActiveSubMenu(response: HttpResponseBase): Observable<SelectActiveSubMenuOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = SelectActiveSubMenuOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<SelectActiveSubMenuOutput>(<any>null);
    }

    /**
     * @return Application sub menu selected successfully.
     */
    selectSubMenuById(applicationSubMenuId: number): Observable<SelectByMenuSubIdOutput> {
        let url_ = this.baseUrl + "/ApplicationMenu/SelectSubMenuById/{applicationSubMenuId}";
        if (applicationSubMenuId === undefined || applicationSubMenuId === null)
            throw new Error("The parameter 'applicationSubMenuId' must be defined.");
        url_ = url_.replace("{applicationSubMenuId}", encodeURIComponent("" + applicationSubMenuId));
        url_ = url_.replace(/[?&]$/, "");

        let options_ : any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Accept": "application/json"
            })
        };

        return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processSelectSubMenuById(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processSelectSubMenuById(<any>response_);
                } catch (e) {
                    return <Observable<SelectByMenuSubIdOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<SelectByMenuSubIdOutput>><any>_observableThrow(response_);
        }));
    }

    protected processSelectSubMenuById(response: HttpResponseBase): Observable<SelectByMenuSubIdOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = SelectByMenuSubIdOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<SelectByMenuSubIdOutput>(<any>null);
    }

    /**
     * @return All application sub menu selected successfully.
     */
    selectApplicationMenuForUserGroup(): Observable<SelectApplicationMenuForUserGroupOutput> {
        let url_ = this.baseUrl + "/ApplicationMenu/SelectApplicationMenuForUserGroup";
        url_ = url_.replace(/[?&]$/, "");

        let options_ : any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Accept": "application/json"
            })
        };

        return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processSelectApplicationMenuForUserGroup(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processSelectApplicationMenuForUserGroup(<any>response_);
                } catch (e) {
                    return <Observable<SelectApplicationMenuForUserGroupOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<SelectApplicationMenuForUserGroupOutput>><any>_observableThrow(response_);
        }));
    }

    protected processSelectApplicationMenuForUserGroup(response: HttpResponseBase): Observable<SelectApplicationMenuForUserGroupOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = SelectApplicationMenuForUserGroupOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<SelectApplicationMenuForUserGroupOutput>(<any>null);
    }

    /**
     * @return All application sub menu selected successfully.
     */
    selectApplicationSubMenuDemoTable(body: SelectApplicationSubMenuDemoTableInput): Observable<SelectApplicationSubMenuDemoTableOutput> {
        let url_ = this.baseUrl + "/ApplicationMenu/SelectApplicationSubMenuDemoTable";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_ : any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("post", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processSelectApplicationSubMenuDemoTable(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processSelectApplicationSubMenuDemoTable(<any>response_);
                } catch (e) {
                    return <Observable<SelectApplicationSubMenuDemoTableOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<SelectApplicationSubMenuDemoTableOutput>><any>_observableThrow(response_);
        }));
    }

    protected processSelectApplicationSubMenuDemoTable(response: HttpResponseBase): Observable<SelectApplicationSubMenuDemoTableOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = SelectApplicationSubMenuDemoTableOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<SelectApplicationSubMenuDemoTableOutput>(<any>null);
    }
}
