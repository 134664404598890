

export class SelectNextIndexNumberOutputData implements ISelectNextIndexNumberOutputData {
    next_index_number!: number;

    constructor(data?: ISelectNextIndexNumberOutputData) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.next_index_number = _data["next_index_number"];
        }
    }

    static fromJS(data: any): SelectNextIndexNumberOutputData {
        data = typeof data === 'object' ? data : {};
        let result = new SelectNextIndexNumberOutputData();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["next_index_number"] = this.next_index_number;
        return data; 
    }
}

export interface ISelectNextIndexNumberOutputData {
    next_index_number: number;
}

