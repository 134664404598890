export enum Paths {
  authentication = 'authentication',
  application = 'application',
  demo = 'demo',
  login = 'login',
  forgotPassword = 'forgot-password',
  resetPassword = 'reset-password',
  form = 'form',
  table = 'table',
  serverTable = 'server-table',
  button = 'button',
  card = 'card',
  dialog = 'dialog',
  input = 'input',
  select = 'select',
  radioCheckbox = 'radio-checkbox',
  dashboard = 'dashboard',

  // Tracking
  tracking = 'tracking',
  advanceTracking = 'advance-tracking',

  //POI
  poi = 'poi',
  poiDetails = 'poi-details',
  addPoiDetails = 'add-poi-details',
  editPoiDetails = 'edit-poi-details',

  // geofence
  geofence = 'geofence',

  // geofence overview inside geofence
  geofenceOverview = 'geofence-overview',
  addGeofenceOverview = 'add-geofence-overview',
  editGeofenceOverview = 'edit-geofence-overview',

  //temperature
  temperature = 'temperature',

  //temperature calibration inside temperature
  temperatureCalibration = 'temperature-calibration',
  addTemperatureCalibration = 'add-temperature-calibration',
  editTemperatureCalibration = 'edit-temperature-calibration',

  temperatureUnit = 'temperature-unit',
  addTemperatureUnit = 'add-temperature-unit',
  editTemperatureUnit = 'edit-temperature-unit',

  //Tyre
  tyre = 'tyre',

  //Tyre List inside tyre 
  tyreList = 'tyre-list',
  addTyre = 'add-tyre',
  editTyre = 'edit-tyre',

  // Tour
  tour = 'tour',

  // Tour Planning inside Tour
  tourPlanning = 'tour-planning',
  addTourPlanning = 'add-tour-planning',
  editTourPlanning = 'edit-tour-planning',

// Tour Allocation inside Tour
  tourAllocation='tour-allocation',
  addTourAllocation='add-tour-allocation',
  editTourAllocation='edit-tour-allocation',


  //Alert Menu Start
  alert = 'alert',

  //alert group overview and add-edit alert inside alert module
  alertGroupOverview = 'alert-group-overview',
  addAlertGroup = 'add-alert-group',
  editAlertGroup = 'edit-alert-group',
  employeesByEmployeeGroup = 'employees-by-employee-group',
  //Alert Menu End

  //Licensing Module Start
  licensing = 'licensing',

  // user license inside Licensing
  userLicense = 'user-detail-license',

  //company-details-license inside licensing  
  companyLicenseDetail = 'company-detail-license',

  //vehicle-details-license inside license
  vehicleLicense = 'vehicle-license',

  //Licensing Module End

  //maintenance Module Start
  maintenance = 'maintenance',

  //expense inside maintenance
  expense = 'expense',
  addExpense = 'add-expense',
  editExpense = 'edit-expense',
  expenseHistory = 'expense-history',

  //reminder inside maintenance
  reminder = 'reminder',
  addReminder = 'add-reminder',
  editReminder = 'edit-reminder',

  //maintenance Module End

  //Advance
  advance = 'advance',

  //route-path inside advance module
  routePath = 'route-path',
  addRoutePath = 'add-route-path',
  editRoutePath = 'edit-route-path',

  //route-path-allocation-group inside advance module
  routePathAllocationGroup = 'route-path-allocation-group',
  addRoutePathAllocationGroup = 'add-route-path-allocation-group',
  editRoutePathAllocationGroup = 'edit-route-path-allocation-group',

  //vehicle-group inside advance
  vehicleGroup = 'vehicle-group',

  //employee-group inside advance
  employeeGroup = 'employee-group',
  addEmployeeGroup = 'add-employee-group',
  editEmployeeGroup = 'edit-employee-group',

  //App Config Menu
  appConfig = 'app-config',

  //company inside app-config
  company = 'company',
  addEditCompany = 'add-edit-company',
  companyMap = 'company-map',
  addEditCompanyMap = 'add-edit-company-map',

  //branch-details inside app-config
  branch = 'branch',
  addEditBranch = 'add-edit-branch',

  //vehicle-details inside app-config
  vehicle = 'vehicle',
  addVehicle = 'add-vehicle',
  updateVehicle = 'update-vehicle',
  vehicleDocument = 'vehicle-document',
  addVehicleDocument = 'add-vehicle-document',
  updateVehicleDocument = 'update-vehicle-document',

  //employee-details inside app-config
  employee = 'employee',
  addEmployee = 'add-employee',
  updateEmployee = 'update-employee',

  //gps device
  gpsDevice = 'gps-device',
  addGpsDevice = 'add-gps-device',
  updateGpsDevice = 'update-gps-device',

  //master data inside app-config
  masterData = 'master-data',

  //company rule inside app-config
  companyRule = 'company-rule',
  addCompanyRule = 'add-company-rule',
  editCompanyRule = 'edit-company-rule',


  //empty remove inside app-config
  emptyRemove = 'empty-remove',
  addEmptyRemove = 'add-empty-remove',

  //sms-allocation inside app-config
  smsAllocation = 'sms-allocation',
  addSmsAllocation = 'add-sms-allocation',
  updateSmsAllocation = 'update-sms-allocation',

  //customize tool tip inside app-config
  customizeToolTip = 'customize-tool-tip',
  addCustomizeToolTip = 'add-customize-tool-tip',
  updateCustomizeToolTip = 'update-customize-tool-tip',

  // add-device inside app-config
  device = 'device',
  addDevice = 'add-device',
  updateDevice = 'update-device',

  //User Rights
  userRights = 'user-rights',
  user = 'user',
  addUser = "add-user",
  editUser = "edit-user",
  userGroupList = "user-group-list",
  addUserGroup = "add-user-group",
  editUserGroup = "edit-user-group",

  //master
  master = 'master',
  applicationMainMenu = 'application-main-menu',
  applicationSubMenu = 'application-sub-menu',
  applicationModule = 'application-module',
  userType = 'user-type',
  employeeDesignation = 'employee-designation',
  language = 'language',
  country = 'country',
  state = 'state',
  city = 'city',
  tyreBrand = 'tyre-brand',
  tyreModel = 'tyre-model',
  companyDetailAlert = 'company-detail-alert',
  tariffPlanOverview = 'tariff-plan-overview',
  simCardOverview = 'sim-card-overview',
  storageDays = 'storage-days',
  map = 'map',
  documentType = 'document-type',
  fuelType = 'fuel-type',
  poiType = 'poi-type',
  fuelStation = 'fuel-station',
  expenseType = 'expense-type',
  maintenanceType = 'maintenance-type',
  vehicleType = 'vehicle-type',
  vehicleCompany = 'vehicle-company',
  vehicleModel = 'vehicle-model',

  // device-brand inside master
  deviceBrand = "device-brand",
  deviceModel = 'device-model',
  deviceModelPortSpecification = 'device-model-port-specification',

  deviceModelPortAllocation = 'device-model-port-allocation',
  licenseToDrive = 'license-to-drive',
  timeZone = 'timezone',

  // custom tool tip inside app-config
  toolTipHeader = 'custom-tool-tip-header',

  //tool-tip-option inside app-config
  toolTipOption = 'custom-tool-tip-option',

  //fuel
  fuel = 'fuel',
  fuelCalibration = 'fuel-calibration',
  addFuelCalibration = 'add-fuel-calibration',
  updateFuelCalibration = 'update-fuel-calibration'
}
