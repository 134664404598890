

export class ForgotPasswordInput implements IForgotPasswordInput {
    /** The user name  of user */
    userName!: string;

    constructor(data?: IForgotPasswordInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.userName = _data["userName"];
        }
    }

    static fromJS(data: any): ForgotPasswordInput {
        data = typeof data === 'object' ? data : {};
        let result = new ForgotPasswordInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["userName"] = this.userName;
        return data; 
    }
}

export interface IForgotPasswordInput {
    /** The user name  of user */
    userName: string;
}

