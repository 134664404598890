import {
  HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LocalStorage } from '../enums';
import { LocalStorageService, SharedService, ToasterService } from '../services';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment.development';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  
  private authUrlExceptions = [
    'User/Login',
    'User/ForgotPassword',
    'User/ResetPassword'
  ];

  constructor(private localStorageService: LocalStorageService,
    private sharedService: SharedService,
    private toasterService: ToasterService,
    private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (request.url.includes(environment.tracCarUrl)) {
      return next.handle(request);
    }

    const index = request.url.indexOf("/v1/");
    if (this.authUrlExceptions.indexOf(request.url.substring(index)) === -1) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.localStorageService.getData(LocalStorage.accessToken)}`
        }
      });
    }

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          this.sharedService.setApiCall(false);
          this.toasterService.showApiValidationError({ status: 401, message: "UnAuthorize Access" });
          this.sharedService.userLogout();
          window.location.href = "/";
          return throwError('');
        }
        else if (err instanceof HttpErrorResponse && err.error instanceof Blob && err.error.type.toLocaleLowerCase().includes("application/json")) {
          this.sharedService.setApiCall(false);
          return new Promise<any>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e: Event) => {
              try {
                const errorMessage = JSON.parse((<any>e.target).result);
                this.toasterService.showApiValidationError(errorMessage);
                reject(
                  new HttpErrorResponse({
                    error: errorMessage,
                    headers: err.headers,
                    status: err.status,
                    statusText: err.statusText,
                    url: err.url || '',
                  })
                );
              } catch (e) {
                reject(err);
              }
            };
            reader.onerror = (err) => {
              reject(err);
            };
            reader.readAsText(err.error);
          });
        }
        else {
          this.sharedService.setApiCall(false);
          this.toasterService.showApiValidationError({ status: err.status, message: err.statusText, title: err.name });
          return throwError(err.error);
        }
      })
    );

  }

}