

export class CompanyDetailOutput implements ICompanyDetailOutput {
    /** The Reference id of the tbl_company_detail table */
    company_details_id!: number;
    /** The company name with 255 character limit */
    company_name!: string;
    /** The company short name with 255 character limit */
    short_name!: string;
    /** The city name with 255 character limit */
    city_name!: string;
    /** The state name with 255 character limit */
    state_name!: string;
    /** The country name with 255 character limit */
    country_name!: string;
    /** The contact person with 255 character limit */
    contact_person!: string;
    /** The help desk number with 13 character limit */
    help_desk_number!: string;
    /** The mobile number with 10 character limit */
    mobile_number!: string;
    /** The speed limit api must be one of 0 for deactive, 1 for active */
    status!: number;
    /** The email id of user with 320 character limit */
    email!: string;

    constructor(data?: ICompanyDetailOutput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.company_details_id = _data["company_details_id"];
            this.company_name = _data["company_name"];
            this.short_name = _data["short_name"];
            this.city_name = _data["city_name"];
            this.state_name = _data["state_name"];
            this.country_name = _data["country_name"];
            this.contact_person = _data["contact_person"];
            this.help_desk_number = _data["help_desk_number"];
            this.mobile_number = _data["mobile_number"];
            this.status = _data["status"];
            this.email = _data["email"];
        }
    }

    static fromJS(data: any): CompanyDetailOutput {
        data = typeof data === 'object' ? data : {};
        let result = new CompanyDetailOutput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["company_details_id"] = this.company_details_id;
        data["company_name"] = this.company_name;
        data["short_name"] = this.short_name;
        data["city_name"] = this.city_name;
        data["state_name"] = this.state_name;
        data["country_name"] = this.country_name;
        data["contact_person"] = this.contact_person;
        data["help_desk_number"] = this.help_desk_number;
        data["mobile_number"] = this.mobile_number;
        data["status"] = this.status;
        data["email"] = this.email;
        return data; 
    }
}

export interface ICompanyDetailOutput {
    /** The Reference id of the tbl_company_detail table */
    company_details_id: number;
    /** The company name with 255 character limit */
    company_name: string;
    /** The company short name with 255 character limit */
    short_name: string;
    /** The city name with 255 character limit */
    city_name: string;
    /** The state name with 255 character limit */
    state_name: string;
    /** The country name with 255 character limit */
    country_name: string;
    /** The contact person with 255 character limit */
    contact_person: string;
    /** The help desk number with 13 character limit */
    help_desk_number: string;
    /** The mobile number with 10 character limit */
    mobile_number: string;
    /** The speed limit api must be one of 0 for deactive, 1 for active */
    status: number;
    /** The email id of user with 320 character limit */
    email: string;
}

