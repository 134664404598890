

export class StorageDaysAddInput implements IStorageDaysAddInput {
    /** The storage days name with 255 character limit */
    storageDaysName!: string;

    constructor(data?: IStorageDaysAddInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.storageDaysName = _data["storageDaysName"];
        }
    }

    static fromJS(data: any): StorageDaysAddInput {
        data = typeof data === 'object' ? data : {};
        let result = new StorageDaysAddInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["storageDaysName"] = this.storageDaysName;
        return data; 
    }
}

export interface IStorageDaysAddInput {
    /** The storage days name with 255 character limit */
    storageDaysName: string;
}

