

export class LicenseToDriveAddInput implements ILicenseToDriveAddInput {
    /** The license to drive name with 255 character limit */
    licenseToDriveName!: string;

    constructor(data?: ILicenseToDriveAddInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.licenseToDriveName = _data["licenseToDriveName"];
        }
    }

    static fromJS(data: any): LicenseToDriveAddInput {
        data = typeof data === 'object' ? data : {};
        let result = new LicenseToDriveAddInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["licenseToDriveName"] = this.licenseToDriveName;
        return data; 
    }
}

export interface ILicenseToDriveAddInput {
    /** The license to drive name with 255 character limit */
    licenseToDriveName: string;
}

