

export class CompanyDetailOutputAlertData implements ICompanyDetailOutputAlertData {
    /** The Reference id of the tbl_company_details_alert table */
    company_details_alert_id!: number;
    /** The Reference id of the tbl_alert table */
    alert_id!: number;
    /** The alert name with 255 character limit */
    alert_name!: string;

    constructor(data?: ICompanyDetailOutputAlertData) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.company_details_alert_id = _data["company_details_alert_id"];
            this.alert_id = _data["alert_id"];
            this.alert_name = _data["alert_name"];
        }
    }

    static fromJS(data: any): CompanyDetailOutputAlertData {
        data = typeof data === 'object' ? data : {};
        let result = new CompanyDetailOutputAlertData();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["company_details_alert_id"] = this.company_details_alert_id;
        data["alert_id"] = this.alert_id;
        data["alert_name"] = this.alert_name;
        return data; 
    }
}

export interface ICompanyDetailOutputAlertData {
    /** The Reference id of the tbl_company_details_alert table */
    company_details_alert_id: number;
    /** The Reference id of the tbl_alert table */
    alert_id: number;
    /** The alert name with 255 character limit */
    alert_name: string;
}

