

export class CompanyDetailAlertInput implements ICompanyDetailAlertInput {
    /** The Reference id of the tbl_alert table */
    alertId!: number;

    constructor(data?: ICompanyDetailAlertInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.alertId = _data["alertId"];
        }
    }

    static fromJS(data: any): CompanyDetailAlertInput {
        data = typeof data === 'object' ? data : {};
        let result = new CompanyDetailAlertInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["alertId"] = this.alertId;
        return data; 
    }
}

export interface ICompanyDetailAlertInput {
    /** The Reference id of the tbl_alert table */
    alertId: number;
}

