

export class CompanyDetailStatusMapInput implements ICompanyDetailStatusMapInput {
    /** The Reference id of the tbl_company_details_map table */
    companyDetailsMapId!: number;
    /** The updated status */
    status!: number;

    constructor(data?: ICompanyDetailStatusMapInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.companyDetailsMapId = _data["companyDetailsMapId"];
            this.status = _data["status"];
        }
    }

    static fromJS(data: any): CompanyDetailStatusMapInput {
        data = typeof data === 'object' ? data : {};
        let result = new CompanyDetailStatusMapInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["companyDetailsMapId"] = this.companyDetailsMapId;
        data["status"] = this.status;
        return data; 
    }
}

export interface ICompanyDetailStatusMapInput {
    /** The Reference id of the tbl_company_details_map table */
    companyDetailsMapId: number;
    /** The updated status */
    status: number;
}

