

export class MapAddInput implements IMapAddInput {
    /** The map name with 255 character limit */
    mapName!: string;

    constructor(data?: IMapAddInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.mapName = _data["mapName"];
        }
    }

    static fromJS(data: any): MapAddInput {
        data = typeof data === 'object' ? data : {};
        let result = new MapAddInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["mapName"] = this.mapName;
        return data; 
    }
}

export interface IMapAddInput {
    /** The map name with 255 character limit */
    mapName: string;
}

